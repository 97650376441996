import _client from "./src/client";
import * as _uuid2 from "uuid";
var _uuid = "default" in _uuid2 ? _uuid2.default : _uuid2;
import _process from "./src/process";
import _version from "./src/version";
import _authtoken from "./src/authtoken";
import _config from "./src/config";
import _utils from "./src/utils";
var exports = {};
/// <reference path="./index.d.ts"/>

const {
  NgrokClient,
  NgrokClientError
} = _client;
const uuid = _uuid;
const {
  getProcess,
  killProcess
} = _process;
const {
  getVersion
} = _version;
const {
  setAuthtoken
} = _authtoken;
const {
  upgradeConfig
} = _config;
const {
  defaults,
  validate,
  isRetriable,
  defaultConfigPath,
  oldDefaultConfigPath
} = _utils;

/**
 * @type string | null
 */
let processUrl = null;
/**
 * @type NgrokClient | null
 */
let ngrokClient = null;

/**
 *
 * @param {Object | string} opts
 * @returns Promise<string>
 */
async function connect(opts) {
  const {
    tunnelOpts,
    globalOpts
  } = defaults(opts);
  validate(globalOpts);
  processUrl = await getProcess(globalOpts);
  ngrokClient = new NgrokClient(processUrl);
  return connectRetry(tunnelOpts);
}
async function connectRetry(opts, retryCount = 0) {
  opts.name = String(opts.name || uuid.v4());
  try {
    const response = await ngrokClient.startTunnel(opts);
    return response.public_url;
  } catch (err) {
    if (!isRetriable(err) || retryCount >= 100) {
      throw err;
    }
    await new Promise(resolve => setTimeout(resolve, 200));
    return connectRetry(opts, ++retryCount);
  }
}

/**
 *
 * @param {string} publicUrl
 * @returns Promise<void>
 */
async function disconnect(publicUrl) {
  if (!ngrokClient) return;
  const tunnels = (await ngrokClient.listTunnels()).tunnels;
  if (!publicUrl) {
    const disconnectAll = tunnels.map(tunnel => disconnect(tunnel.public_url));
    return Promise.all(disconnectAll);
  }
  const tunnelDetails = tunnels.find(tunnel => tunnel.public_url === publicUrl);
  if (!tunnelDetails) {
    throw new Error(`there is no tunnel with url: ${publicUrl}`);
  }
  return ngrokClient.stopTunnel(tunnelDetails.name);
}

/**
 *
 * @returns Promise<void>
 */
async function kill() {
  if (!ngrokClient) return;
  await killProcess();
  ngrokClient = null;
}

/**
 *
 * @returns string | null
 */
function getUrl() {
  return processUrl;
}

/**
 *
 * @returns NgrokClient | null
 */
function getApi() {
  return ngrokClient;
}
exports = {
  connect,
  disconnect,
  authtoken: setAuthtoken,
  defaultConfigPath,
  oldDefaultConfigPath,
  upgradeConfig,
  kill,
  getUrl,
  getApi,
  getVersion,
  NgrokClient,
  NgrokClientError
};
export default exports;
export const authtoken = exports.authtoken;
const _connect = exports.connect,
  _disconnect = exports.disconnect,
  _defaultConfigPath = exports.defaultConfigPath,
  _oldDefaultConfigPath = exports.oldDefaultConfigPath,
  _upgradeConfig = exports.upgradeConfig,
  _kill = exports.kill,
  _getUrl = exports.getUrl,
  _getApi = exports.getApi,
  _getVersion = exports.getVersion,
  _NgrokClient = exports.NgrokClient,
  _NgrokClientError = exports.NgrokClientError;
export { _connect as connect, _disconnect as disconnect, _defaultConfigPath as defaultConfigPath, _oldDefaultConfigPath as oldDefaultConfigPath, _upgradeConfig as upgradeConfig, _kill as kill, _getUrl as getUrl, _getApi as getApi, _getVersion as getVersion, _NgrokClient as NgrokClient, _NgrokClientError as NgrokClientError };