import * as _path2 from "path";
var _path = "default" in _path2 ? _path2.default : _path2;
import * as _os2 from "os";
var _os = "default" in _os2 ? _os2.default : _os2;
var exports = {};
const {
  join
} = _path;
const platform = _os.platform();
const defaultDir = join(new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname, "..", "bin");
const bin = platform === "win32" ? "ngrok.exe" : "ngrok";
const ready = /starting web service.*addr=(\d+\.\d+\.\d+\.\d+:\d+)/;
const inUse = /address already in use/;
exports = {
  defaultDir,
  bin,
  ready,
  inUse
};
export default exports;