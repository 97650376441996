import * as _child_process2 from "child_process";
var _child_process = "default" in _child_process2 ? _child_process2.default : _child_process2;
import * as _path2 from "path";
var _path = "default" in _path2 ? _path2.default : _path2;
import _constants from "./constants";
import _process from "process";
var exports = {};
var process = _process;
const {
  spawn
} = _child_process;
const {
  join
} = _path;
const {
  defaultDir,
  bin,
  ready,
  inUse
} = _constants;
let processPromise, activeProcess;

/*
  ngrok process runs internal ngrok api
  and should be spawned only ONCE
  (respawn allowed if it fails or .kill method called)
*/
async function getProcess(opts) {
  if (processPromise) return processPromise;
  try {
    processPromise = startProcess(opts);
    return await processPromise;
  } catch (ex) {
    processPromise = null;
    throw ex;
  }
}
function parseAddr(message) {
  if (message[0] === "{") {
    const parsed = JSON.parse(message);
    return parsed.addr;
  } else {
    const parsed = message.match(ready);
    if (parsed) {
      return parsed[1];
    }
  }
}
async function startProcess(opts) {
  let dir = defaultDir;
  const start = ["start", "--none", "--log=stdout"];
  if (opts.authtoken) start.push(`--authtoken=${opts.authtoken}`);
  if (opts.region) start.push(`--region=${opts.region}`);
  if (opts.configPath) start.push(`--config=${opts.configPath}`);
  if (opts.binPath) dir = opts.binPath(dir);
  const ngrok = spawn(join(dir, bin), start, {
    windowsHide: true
  });
  let resolve, reject;
  const apiUrl = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  ngrok.stdout.on("data", data => {
    const msg = data.toString().trim();
    if (opts.onLogEvent) {
      opts.onLogEvent(msg);
    }
    if (opts.onStatusChange) {
      if (msg.match("client session established")) {
        opts.onStatusChange("connected");
      } else if (msg.match("session closed, starting reconnect loop")) {
        opts.onStatusChange("closed");
      }
    }
    const msgs = msg.split(/\n/);
    msgs.forEach(msg => {
      const addr = parseAddr(msg);
      if (addr) {
        resolve(`http://${addr}`);
      } else if (msg.match(inUse)) {
        reject(new Error(msg.substring(0, 10000)));
      }
    });
  });
  ngrok.stderr.on("data", data => {
    const msg = data.toString().substring(0, 10000);
    reject(new Error(msg));
  });
  ngrok.on("exit", () => {
    processPromise = null;
    activeProcess = null;
    if (opts.onTerminated) {
      opts.onTerminated();
    }
  });
  try {
    const url = await apiUrl;
    activeProcess = ngrok;
    return url;
  } catch (ex) {
    ngrok.kill();
    throw ex;
  } finally {
    // Remove the stdout listeners if nobody is interested in the content.
    if (!opts.onLogEvent && !opts.onStatusChange) {
      ngrok.stdout.removeAllListeners("data");
    }
    ngrok.stderr.removeAllListeners("data");
  }
}
function killProcess() {
  if (!activeProcess) {
    return Promise.resolve();
  }
  return new Promise(resolve => {
    activeProcess.on("exit", () => resolve());
    activeProcess.kill();
  });
}
process.on("exit", () => {
  if (activeProcess) {
    activeProcess.kill();
  }
});
exports = {
  getProcess,
  killProcess
};
export default exports;