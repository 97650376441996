import * as _util2 from "util";
var _util = "default" in _util2 ? _util2.default : _util2;
import * as _child_process2 from "child_process";
var _child_process = "default" in _child_process2 ? _child_process2.default : _child_process2;
import * as _path2 from "path";
var _path = "default" in _path2 ? _path2.default : _path2;
import _constants from "./constants";
var exports = {};
const {
  promisify
} = _util;
const {
  exec: execCallback
} = _child_process;
const {
  join
} = _path;
const exec = promisify(execCallback);
const {
  defaultDir,
  bin
} = _constants;

/**
 * @param {Ngrok.Options | undefined} opts
 * @returns Promise<string>
 */
async function getVersion(opts = {}) {
  let dir = defaultDir;
  if (opts.binPath) {
    dir = opts.binPath(dir);
  }
  const {
    stdout
  } = await exec(`${join(dir, bin)} --version`);
  return stdout.replace("ngrok version", "").trim();
}
exports = {
  getVersion
};
export default exports;