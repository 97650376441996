import * as _child_process2 from "child_process";
var _child_process = "default" in _child_process2 ? _child_process2.default : _child_process2;
import * as _path2 from "path";
var _path = "default" in _path2 ? _path2.default : _path2;
import _constants from "./constants";
var exports = {};
const childProcess = _child_process;
const {
  join
} = _path;
const {
  defaultDir,
  bin
} = _constants;
function consolidateTokenAndOpts(optsOrToken) {
  const isOpts = typeof optsOrToken !== "string";
  const opts = isOpts ? optsOrToken : {};
  const token = isOpts ? opts.authtoken : optsOrToken;
  opts.authtoken = token;
  return opts;
}
async function setAuthtoken(optsOrToken) {
  const opts = consolidateTokenAndOpts(optsOrToken);
  const command = ["config", "add-authtoken", opts.authtoken];
  if (opts.configPath) {
    command.push("--config=" + opts.configPath);
  }
  let dir = defaultDir;
  if (opts.binPath) {
    dir = opts.binPath(dir);
  }
  const process = childProcess.spawn(join(dir, bin), command, {
    windowsHide: true
  });
  const killed = new Promise((resolve, reject) => {
    process.stdout.once("data", () => resolve());
    process.stderr.once("data", () => reject(new Error("cant set authtoken")));
  });
  try {
    return await killed;
  } finally {
    process.kill();
  }
}
exports = {
  setAuthtoken
};
export default exports;