import * as _child_process2 from "child_process";
var _child_process = "default" in _child_process2 ? _child_process2.default : _child_process2;
import * as _path2 from "path";
var _path = "default" in _path2 ? _path2.default : _path2;
import _constants from "./constants";
var exports = {};
const {
  spawn
} = _child_process;
const {
  join
} = _path;
const {
  defaultDir,
  bin
} = _constants;

/**
 *
 * Upgrades ngrok config file for use with ngrok version 3. See https://ngrok.com/docs/guides/upgrade-v2-v3#upgrading-the-ngrok-agent-config for more details
 *
 * @param {Object} opts - Options for upgrading your config file
 * @param {boolean} opts.relocate - Whether to relocate your config to the default directory
 * @param {string} opts.configPath - The path to your config file
 * @param {(defaultPath: string) => string} opts.binPath - Custom binary path, eg for prod in electron
 */
async function upgradeConfig(opts) {
  const command = ["config", "upgrade"];
  if (opts.relocate) {
    command.push("--relocate");
  }
  if (opts.configPath) {
    command.push(`--config=${opts.configPath}`);
  }
  let dir = defaultDir;
  if (opts.binPath) {
    dir = opts.binPath(dir);
  }
  const process = spawn(join(dir, bin), command, {
    windowsHide: true
  });
  const killed = new Promise((resolve, reject) => {
    process.stdout.once("data", () => resolve());
    process.stderr.once("data", () => reject(new Error("Error upgrading config")));
  });
  try {
    return await killed;
  } finally {
    process.kill();
  }
}
exports = {
  upgradeConfig
};
export default exports;